import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './screens/home/Home';
import Game from './screens/game/Game';
import About from './screens/about/About';
import Settings from './screens/settings/Settings';
import PlayMenu from './screens/playMenu/PlayMenu';

import './utils/root.scss';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/game/:mode" element={<Game />} />
          <Route path="/lobby" element={<PlayMenu />} />
          <Route path="/about" element={<About />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
