import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import s from './gameSolo.module.scss';
import Snake from '../snake/Snake';
import MenuButton from '../menuButton/MenuButton';
import LifeImg from "../../utils/imgs/minecraft-heart.svg";
import loseLifeSound from "../../utils/sounds/ouch.mp3";
import gameOverVideo from "../../utils/videos/directed_by.mp4";

const GameSolo = () => {
    const [snakeData, setSnakeData] = useState([
        [0, 0],
        [10, 0]
    ]);
    const [score, setScore] = useState(0); // Score du joueur
    const [snakeLength, setSnakeLength] = useState(2); // Longueur initiale du Snake
    const [level, setLevel] = useState(1); // Niveau du joueur
    const [speed, setSpeed] = useState(4); // Vitesse du Snake
    const [timePlayed, setTimePlayed] = useState(0); // Temps joué en secondes
    const [finalTimePlayed, setFinalTimePlayed] = useState(null); // Temps final pour l'écran de Game Over
    const [lives, setLives] = useState(3); // Vies restantes
    const [collisionDetected, setCollisionDetected] = useState(false); // Drapeau pour contrôler la collision
    const [gameOver, setGameOver] = useState(false); // État de fin de partie (pour la vidéo)

    const timer = useRef(0);
    const growthTimer = useRef(0);
    const direction = useRef('RIGHT');
    const canChangeDirection = useRef(true);

    const boardSize = { width: 500, height: 500 }; // taille de la carte

    // Démarrage du chronomètre
    useEffect(() => {
        const interval = setInterval(() => {
            if (!gameOver) {
                setTimePlayed(prev => prev + 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [gameOver]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        gsap.ticker.add(gameLoop);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            gsap.ticker.remove(gameLoop);
        };
    }, [snakeData]);

    const moveSnake = () => {
        if (gameOver) return; // Arrêter le mouvement si le jeu est terminé

        let newSnakeData = [...snakeData];
        let head = newSnakeData[newSnakeData.length - 1];

        // Déplacement en fonction de la direction
        switch (direction.current) {
            case 'UP':
                head = [head[0], head[1] - 10];
                break;
            case 'DOWN':
                head = [head[0], head[1] + 10];
                break;
            case 'LEFT':
                head = [head[0] - 10, head[1]];
                break;
            case 'RIGHT':
                head = [head[0] + 10, head[1]];
                break;
            default:
                break;
        }

        newSnakeData.push(head);
        newSnakeData.shift();

        setSnakeData(newSnakeData);

        // Vérifier les collisions
        checkCollision(head, newSnakeData);
    };

    const growSnake = () => {
        if (gameOver) return; // Empêcher la croissance si le jeu est terminé

        let newSnakeData = [...snakeData];
        let tail = newSnakeData[0];
        newSnakeData.unshift(tail);

        setSnakeData(newSnakeData);
        setSnakeLength(newSnakeData.length);
        setScore(score + 10); // Augmenter le score à chaque croissance
    };

    // Vérifier les collisions avec les bords et le corps du Snake
    const checkCollision = (head, snakeBody) => {
        const [x, y] = head;

        if (collisionDetected) {
            return;
        }

        // Collision avec les bords
        if (x < 0 || x >= boardSize.width || y < 0 || y >= boardSize.height) {
            loseLife();
            autoTurnSnake(x, y);
            setCollisionDetected(true);
            setTimeout(() => setCollisionDetected(false), 500);
            return;
        }

        // Collision avec son propre corps
        for (let i = 0; i < snakeBody.length - 1; i++) {
            if (snakeBody[i][0] === x && snakeBody[i][1] === y) {
                loseLife();
                setCollisionDetected(true);
                setTimeout(() => setCollisionDetected(false), 500); // Attendre 500 ms avant de permettre une nouvelle collision
                break;
            }
        }
    };

    // Fonction pour réorienter le Snake lorsque qu'il touche un bord
    const autoTurnSnake = (x, y) => {
        // Si le Snake touche le bord gauche ou droit
        if (x < 0) {
            direction.current = 'RIGHT';
        } else if (x >= boardSize.width) {
            direction.current = 'LEFT';
        }

        // Si le Snake touche le bord haut ou bas
        if (y < 0) {
            direction.current = 'DOWN';
        } else if (y >= boardSize.height) {
            direction.current = 'UP';
        }
    };

    const loseLife = () => {
        // Jouer le son de perte de vie
        const audio = new Audio(loseLifeSound);
        audio.play();

        setLives(prev => prev - 1);
        if (lives - 1 === 0) {
            setFinalTimePlayed(timePlayed); // Stocker le temps final
            setGameOver(true); // Affiche la vidéo de game over
        }
    };

    const resetGame = () => {
        setSnakeData([
            [0, 0],
            [10, 0]
        ]);
        setScore(0);
        setSnakeLength(2);
        setLevel(1);
        setSpeed(4);
        setLives(3);
        setTimePlayed(0);
        setCollisionDetected(false);  // Réinitialiser le drapeau de collision
        direction.current = 'RIGHT';  // Réinitialiser la direction vers la droite
        setFinalTimePlayed(null); // Réinitialiser le temps final
        setGameOver(false); // Masquer la vidéo de fin de jeu
    };

    const onKeyDown = (e) => {
        if (canChangeDirection.current === false || gameOver) return;
        canChangeDirection.current = false;
        switch (e.keyCode) {
            case 38:
            case 90: // Flèche haut ou Z
                if (direction.current === 'DOWN') return;
                direction.current = 'UP';
                break;
            case 40:
            case 83: // Flèche bas ou S
                if (direction.current === 'UP') return;
                direction.current = 'DOWN';
                break;
            case 37:
            case 81: // Flèche gauche ou Q
                if (direction.current === 'RIGHT') return;
                direction.current = 'LEFT';
                break;
            case 39:
            case 68: // Flèche droite ou D
                if (direction.current === 'LEFT') return;
                direction.current = 'RIGHT';
                break;
            default:
                break;
        }
    };

    const gameLoop = (time, deltaTime, frame) => {
        if (gameOver) return; // Arrêter la boucle de jeu si le jeu est terminé

        timer.current += deltaTime;
        growthTimer.current += deltaTime;

        if (timer.current > 100) {
            timer.current = 0;
            canChangeDirection.current = true;
            moveSnake();
        }

        if (growthTimer.current > 2000) {
            growthTimer.current = 0;
            growSnake();
        }
    };

    if (gameOver) {
        return (
            
            <div className={s.gameOverContainer}>
                <video autoPlay loop className={s.gameOverVideo}>
                    <source src={gameOverVideo} type="video/mp4" />
                </video>
                <div className={s.statsOverlay}>
                    <div className={s.stats}>
                        <div className={s.scoreGameOver}>
                            <p>Score:</p>
                            <span>{score}</span>
                        </div>
                        <div className={s.timeGameOver}>
                            <p>Time Played:</p>
                            <span>{`${Math.floor(finalTimePlayed / 60).toString().padStart(2, '0')}:${(finalTimePlayed % 60).toString().padStart(2, '0')}`}</span>
                        </div>
                        <div>
                            <p>Snake Length:</p>
                            <span>{snakeLength}</span>
                        </div>
                    </div>
                    <button onClick={resetGame}>Play Again</button>
                    <MenuButton label= "Return Lobby" route="/home"/>
                </div>
            </div>
        );
    }

    return (
        <div className={s.GameSolo}>
            <div className={s.Board}>
                <div className={s.timePlayed}>
                    <p>Time Played</p>
                    <p>{`${Math.floor(timePlayed / 60).toString().padStart(2, '0')}:${(timePlayed % 60).toString().padStart(2, '0')}`}</p>
                </div>
                <div className={s.SnakeLength}>
                    <p>Snake Length</p>
                    <p>{snakeLength}</p>
                </div>
                <div className={s.level}>
                    <p>Level</p>
                    <p>{level}</p>
                </div>
                <div className={s.speed}>
                    <p>Speed</p>
                    <p>{speed}</p>
                </div>
            </div>

            <div className={s.GameSquare} style={{ width: `${boardSize.width}px`, height: `${boardSize.height}px` }}>
                <Snake data={snakeData} />
            </div>

            <div className={s.GameLife}>
                <p>Life</p>
                <div className={s.LifeDiv}>
                    {Array.from({ length: lives }, (_, i) => (
                        <img key={i} src={LifeImg} alt="Life" className={s.heart} />
                    ))}
                </div>
            </div>

            <div className={s.ScoreNumber}>
                <p>Score</p>
                <p>{score}</p>
            </div>
        </div>
    );
}

export default GameSolo;
