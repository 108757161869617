import s from './about.module.scss';


const About = () => {
    return (
        <div>
            <h1 className={s.title}>About</h1>
            <p className={s.text}>This is a simple memory game created with React.</p>
        </div>
    );
}

export default About;