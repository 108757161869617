import React from "react";
import s from './home.module.scss';

import MenuButton from "../../components/menuButton/MenuButton";
import Header from "../../components/header/Header";

const Home = () => {
    return (
        <div>
            <Header />
            <menu>
                <div className={s.bsepa}></div>
                <MenuButton label= "PLAY" route="/lobby"/>
                <div className={s.bsepa}></div>
                <MenuButton label= "ABOUT" route="/about"/>
                <div className={s.bsepa}></div>
                <MenuButton label= "SETTINGS" route="/settings"/>
                <div className={s.bsepa}></div>
            </menu>
            <div className={s.ranking}>
                <div className={s.rankingTitle}>
                    <h1>Ranking</h1>
                </div>
                <div>
                    Coming soon...
                </div>
            </div>
        </div>
    );
}

export default Home;