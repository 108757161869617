import React from 'react';
import { useParams } from 'react-router-dom';
import GameSolo from '../../components/gameSolo/GameSolo';

const Game = () => {
    const { mode } = useParams();

    return (
        <div>
            {mode === 'solo' && <GameSolo />}
            {mode === '1v1' && <p>test 1V1</p>}
            {mode === 'multiplayer' && <p>test Multiplayer</p>}
        </div>
    );
};

export default Game;
