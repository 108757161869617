import React from 'react';
import { useNavigate } from 'react-router-dom';

const MenuButton = ({ label, route, disabled = false }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (!disabled) {
            navigate(route);
        }
    };

    return (
        <button onClick={handleClick} disabled={disabled} style={disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {}}>
            {label}
        </button>
    );
};

export default MenuButton;
