import React from 'react';
import s from './playMenu.module.scss';
import MenuButton from '../../components/menuButton/MenuButton';


const PlayMenu = () => {
    return (
        <div>
            {/* <Header /> */}
            <menu>
                <div className={s.bsepa}></div>
                <MenuButton label= "SOLO" route="/game/solo"/>
                <div className={s.bsepa}></div>
                <MenuButton label= "1 vs 1" route="/about" disabled={true}/>
                <div className={s.bsepa}></div>
                <MenuButton label= "Multiplayer" route="/settings" disabled={true}/>
                <div className={s.bsepa}></div>
            </menu>
        </div>
    );
}

export default PlayMenu;